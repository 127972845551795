import React, { AllHTMLAttributes, ComponentProps, useEffect } from 'react';
import { Controller, UseControllerOptions } from 'react-hook-form';
import { Input as AdaptInput } from '@angellist/adapt';
import isRequired from './util/isRequired';
import onNumberInputFilter from './util/onNumberInputFilter';
import { formatToNumber } from '../../utils/number';
import FieldLoader from '../FieldLoader';

type NativeInputProps = AllHTMLAttributes<HTMLInputElement>;

type NumberInputProps = Pick<ComponentProps<typeof AdaptInput>, 'prefix'> & {
  label?: string;
  name: string;
  isRequired?: boolean;
  placeholder?: string | number;
  defaultValue?: string | number;
  maxLength?: number;
  decimalsLimit?: number;
  type?: 'percentage' | 'number';
  onChange?: (e: number | '' | '-') => void;
  onBlur?: NativeInputProps['onBlur'];
  onKeyUp?: NativeInputProps['onKeyUp'];
  step?: NativeInputProps['step'];
  min?: number;
  max?: number;
  compact?: boolean;
  inputMode?: 'numeric' | 'decimal';
  autoFocus?: boolean;
  shouldUnregister?: boolean;
  suffix?: any;
  allowNegative?: boolean;
  description?: string;
};

export type OtherProps = {
  isLoading?: boolean;
  error?: string;
  disabled?: boolean;
};

export type Props = NumberInputProps &
  OtherProps &
  Omit<UseControllerOptions, 'render'>;

const useInputValue = (
  controlledValue: string | number,
  options: {
    isDecimalMode: boolean;
    allowNegative: boolean;
  },
) => {
  // Issue: After adapt 46.2.1 release, the input value is not allowing decimal values (.)
  // so added local state to handle the input value
  const { isDecimalMode, allowNegative } = options;
  const [inputValue, setInputValue] = React.useState<string | number>(
    controlledValue,
  );
  useEffect(() => {
    if (
      isDecimalMode &&
      inputValue !== controlledValue &&
      !inputValue?.toString().endsWith('.')
    ) {
      setInputValue(controlledValue);
    }
  }, [controlledValue]);

  const getControlledValue = (userValue: string) => {
    if (isDecimalMode) {
      setInputValue(userValue);
    }
    let sanitizedVal;
    if (!allowNegative && userValue.includes('-')) {
      sanitizedVal = userValue.replace('-', '');
    } else {
      sanitizedVal = userValue;
    }
    const numberValue =
      sanitizedVal === '-' ? '-' : formatToNumber(sanitizedVal);

    return numberValue;
  };

  return {
    value: isDecimalMode ? inputValue : controlledValue,
    getControlledValue,
  };
};

export const NumberInput = ({
  control,
  defaultValue,
  name,
  isRequired: isRequiredProp,
  rules,
  disabled,
  error,
  onChange,
  isLoading,
  inputMode = 'decimal',
  autoFocus,
  decimalsLimit = 2,
  shouldUnregister,
  allowNegative = false,
  ...providedInputProps
}: Props) => {
  if (isLoading) {
    return <FieldLoader />;
  }
  const isDecimalMode = inputMode === 'decimal';
  const maxDecimals = isDecimalMode ? decimalsLimit : 0;

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      rules={{ ...rules, required: rules?.required ?? isRequiredProp }}
      shouldUnregister={shouldUnregister}
      render={(field) => {
        const { value, getControlledValue } = useInputValue(field.value ?? '', {
          isDecimalMode,
          allowNegative,
        });

        const handleOnChange = (val: string) => {
          const numberValue = getControlledValue(val);
          field.onChange(numberValue);
          if (onChange) {
            onChange(numberValue);
          }
        };

        return (
          <AdaptInput
            {...field}
            inputMode={inputMode}
            errorMessage={error || undefined}
            isDisabled={disabled}
            isRequired={isRequired(rules?.required, isRequiredProp)}
            {...providedInputProps}
            value={value}
            onChange={handleOnChange}
            onInputFilter={(v) => onNumberInputFilter(v, maxDecimals)}
            autoFocus={autoFocus}
          />
        );
      }}
    />
  );
};

export default NumberInput;
